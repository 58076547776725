import React from 'react'

export default function Laptop({ color = 'currentColor' }: { color: string }) {
  return (
    <svg width='31' height='30' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Laptop'>
        <path
          id='Vector'
          d='M4.8125 20.625V8.4375C4.8125 7.94022 5.01004 7.46331 5.36167 7.11167C5.71331 6.76004 6.19022 6.5625 6.6875 6.5625H23.5625C24.0598 6.5625 24.5367 6.76004 24.8883 7.11167C25.24 7.46331 25.4375 7.94022 25.4375 8.4375V20.625'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M2.9375 20.625H27.3125V22.5C27.3125 22.9973 27.115 23.4742 26.7633 23.8258C26.4117 24.1775 25.9348 24.375 25.4375 24.375H4.8125C4.31522 24.375 3.83831 24.1775 3.48667 23.8258C3.13504 23.4742 2.9375 22.9973 2.9375 22.5V20.625Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M17 10.3125H13.25'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}
