import React from 'react'

export default function Card({ children, shadowColor = 'black', className = '', zIndex }) {
  const shadow = {
    black: 'shadow-[8px_8px_0px_black]',
    teal: 'shadow-[8px_8px_0px_rgb(68,196,193)]',
  }

  return (
    <div className={`flex grow justify-center p-6 pt-4 lg:pt-6 ${className}`}>
      <div
        className={`flex h-full w-full rounded-2xl border-2 border-black bg-howdy-dark-wheat ${shadow[shadowColor]} ${zIndex}`}
      >
        {children}
      </div>
    </div>
  )
}
