import { Children, cloneElement, useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import MobileStepper from '@howdy/core/components/MobileStepper'

export default function Carousel({ children }) {
  const [activeIndex, setActive] = useState(0)

  const [swipe, setSwipe] = useState(0)
  const swipeThreshold = 100

  const preventDefault = (e) => {
    if (swipe) {
      e.preventDefault()
      e.returnValue = false
      return false
    }
  }

  useEffect(() => {
    window.addEventListener('touchmove', preventDefault, { passive: false })
    return () => {
      window.removeEventListener('touchmove', preventDefault, { passive: false })
    }
  })

  const handlers = useSwipeable({
    onTouchEndOrOnMouseUp: () => setSwipe(0),
    onSwiping: (e) => setSwipe(Math.round(e.deltaX)),
    onSwipedLeft: ({ deltaX }) => {
      if (Math.abs(deltaX) >= swipeThreshold) {
        if (activeIndex < Children.count(children) - 1) {
          setActive(activeIndex + 1)
        } else {
          setActive(0)
        }
      }
    },
    onSwipedRight: ({ deltaX }) => {
      if (Math.abs(deltaX) >= swipeThreshold) {
        if (activeIndex > 0) {
          setActive(activeIndex - 1)
        } else {
          setActive(Children.count(children) - 1)
        }
      }
    },
  })

  return (
    <div className='flex w-full flex-col items-center'>
      <div className='w-full overflow-hidden' {...handlers}>
        <div
          className={`whitespace-nowrap ${!swipe ? 'transition-transform' : ''}`}
          style={{ transform: `translateX(calc(${10 - activeIndex * 80}% + ${swipe}px))` }}
        >
          {Children.map(children, (child, index) => {
            return cloneElement(child, { width: '80%' })
          })}
        </div>
      </div>
      <MobileStepper numberOfSteps={Children.count(children)} currentStep={activeIndex} />
    </div>
  )
}
