import cn from 'classnames'
import Head from 'next/head'
import DesktopStepper from '../../DesktopStepper'
import Carousel from '../Carousel'
import CarouselItem from '../Carousel/CarouselItem'
import IconCard from '../Card/IconCard'
import SignIn from '@howdy/core/elements/Icons/SignIn'
import ChatTeardropDots from '@howdy/core/elements/Icons/ChatTeardropDots'
import Sparkle from '@howdy/core/elements/Icons/Splarke'
import UsersThree from '@howdy/core/elements/Icons/UsersThree'
import CheckCircle from '@howdy/core/elements/Icons/CheckCircle'
import Laptop from '@howdy/core/elements/Icons/Laptop'

const WelcomeLayout = ({ children, pageTitle, title, showStepper = true }) => {
  const steps = [
    { title: 'Sign-up for free', icon: <SignIn /> },
    { title: 'Start your team request', icon: <ChatTeardropDots /> },
    { title: 'We recruit candidates that match your request', icon: <Sparkle /> },
    { title: 'We pre-vet candidates', icon: <UsersThree width={30} height={30} /> },
    {
      title: 'You interview the most talented candidates',
      icon: <CheckCircle width={30} height={30} />,
    },
    { title: 'You start working with your new teammates​', icon: <Laptop /> },
  ]

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <div className='row flex h-full w-full'>
        <div className='hidden h-full w-full bg-[#F3DDC4] bg-cover bg-left-bottom bg-no-repeat lg:block'>
          <div className='absolute flex h-full w-full items-end justify-center'>
            <img src='/howdy-bg.png' className='h-[80%]' />
          </div>
          <img src='/waves.png' className='absolute bottom-[77px] left-[calc(50%-50px)]' />
          <div
            className={cn(
              'flex h-full w-[500px] flex-col items-start pb-20 pl-14 pt-[13vh]',
              'xsm:hidden',
              { 'from-transparent via-transparent': !showStepper },
            )}
          >
            {showStepper && (
              <div className='flex h-full flex-col gap-9'>
                <h1 className='font-worksans text-[32px] font-bold'>How it works</h1>
                <DesktopStepper
                  className={'text-black'}
                  steps={steps}
                  currentStep={6}
                  joinColor={'border-[#FF9E00]'}
                  stepsColor={'[#FF9E00]'}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={cn(
            'flex w-full flex-col justify-start bg-white',
            'lg:h-full lg:w-3/5 lg:overflow-auto',
            'lg:px-[3%]',
            'lg:border-l lg:border-black',
            'z-20',
          )}
        >
          <div className='flex h-full w-full flex-col items-center justify-between overflow-y-auto overflow-x-hidden px-5 pb-[50px] pt-[13vh] lg:items-start'>
            <div className={'w-full'}>
              <p className='z-20 text-center font-worksans text-5xl font-black leading-[52px] text-black lg:w-1/2 lg:text-left'>
                {title}
              </p>
              {children}
            </div>
            {showStepper && (
              <div className='mt-[45px] flex w-[calc(100%_+_40px)] flex-col items-center lg:hidden'>
                <p className='mb-2 font-worksans text-xl font-bold'>How it works</p>
                <Carousel>
                  {steps.map(({ title, icon }, index) => (
                    <CarouselItem key={index}>
                      <IconCard icon={icon}>
                        <div className='flex w-full items-center justify-center px-[20px] text-center font-worksans text-lg font-bold'>
                          <p className='flex flex-col'>
                            {title.length < 24 ? (
                              <>
                                {title.slice(0, title.lastIndexOf(' '))}
                                <span>{title.slice(title.lastIndexOf(' '))}</span>
                              </>
                            ) : (
                              title
                            )}
                          </p>
                        </div>
                      </IconCard>
                    </CarouselItem>
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default WelcomeLayout
