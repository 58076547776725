const SignIn = () => (
  <svg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.1934 19.9928L16.1152 15.071L11.1934 10.1491'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.99023 15.071H16.1152'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.1152 4.75847H22.6777C22.9264 4.75847 23.1648 4.85724 23.3406 5.03306C23.5165 5.20887 23.6152 5.44733 23.6152 5.69597V24.446C23.6152 24.6946 23.5165 24.9331 23.3406 25.1089C23.1648 25.2847 22.9264 25.3835 22.6777 25.3835H16.1152'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SignIn
