import React from 'react'

export default function ChatTeardropDots({ width = 30, height = 30 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.6466 25.7168H5.76766C5.64872 25.7184 5.53068 25.6961 5.42049 25.6513C5.3103 25.6065 5.2102 25.5401 5.12609 25.456C5.04199 25.3719 4.97558 25.2718 4.93079 25.1616C4.886 25.0514 4.86374 24.9334 4.86531 24.8145V14.9355C4.86531 12.0762 6.00119 9.33393 8.02307 7.31205C10.0449 5.29018 12.7872 4.1543 15.6466 4.1543V4.1543C17.0624 4.1543 18.4643 4.43316 19.7724 4.97497C21.0804 5.51678 22.2689 6.31092 23.2701 7.31205C24.2712 8.31318 25.0653 9.5017 25.6071 10.8097C26.1489 12.1178 26.4278 13.5197 26.4278 14.9355V14.9355C26.4278 16.3514 26.1489 17.7533 25.6071 19.0614C25.0653 20.3694 24.2712 21.5579 23.2701 22.559C22.2689 23.5602 21.0804 24.3543 19.7724 24.8961C18.4643 25.4379 17.0624 25.7168 15.6466 25.7168V25.7168Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.6465 16.5762C16.2937 16.5762 16.8184 16.0515 16.8184 15.4043C16.8184 14.7571 16.2937 14.2324 15.6465 14.2324C14.9993 14.2324 14.4746 14.7571 14.4746 15.4043C14.4746 16.0515 14.9993 16.5762 15.6465 16.5762Z'
        fill='currentColor'
      />
      <path
        d='M21.2715 16.5762C21.9187 16.5762 22.4434 16.0515 22.4434 15.4043C22.4434 14.7571 21.9187 14.2324 21.2715 14.2324C20.6243 14.2324 20.0996 14.7571 20.0996 15.4043C20.0996 16.0515 20.6243 16.5762 21.2715 16.5762Z'
        fill='currentColor'
      />
      <path
        d='M10.0215 16.5762C10.6687 16.5762 11.1934 16.0515 11.1934 15.4043C11.1934 14.7571 10.6687 14.2324 10.0215 14.2324C9.37428 14.2324 8.84961 14.7571 8.84961 15.4043C8.84961 16.0515 9.37428 16.5762 10.0215 16.5762Z'
        fill='currentColor'
      />
    </svg>
  )
}
