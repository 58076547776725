import React from 'react'

export default function Sparkle({ width = 28, height = 28, color = 'currentColor' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.4316 21.304L14.1816 27.4095C14.115 27.5889 13.9951 27.7437 13.838 27.8529C13.6808 27.9622 13.494 28.0208 13.3027 28.0208C13.1113 28.0208 12.9245 27.9622 12.7674 27.8529C12.6103 27.7437 12.4904 27.5889 12.4238 27.4095L10.1738 21.304C10.1263 21.1752 10.0514 21.0581 9.95428 20.961C9.85717 20.8639 9.74013 20.789 9.61126 20.7415L3.5058 18.4915C3.32638 18.4249 3.17164 18.305 3.06237 18.1479C2.9531 17.9908 2.89453 17.804 2.89453 17.6126C2.89453 17.4213 2.9531 17.2345 3.06237 17.0774C3.17164 16.9202 3.32638 16.8003 3.5058 16.7337L9.61126 14.4837C9.74013 14.4362 9.85717 14.3614 9.95428 14.2642C10.0514 14.1671 10.1263 14.0501 10.1738 13.9212L12.4238 7.81576C12.4904 7.63635 12.6103 7.48161 12.7674 7.37234C12.9245 7.26307 13.1113 7.2045 13.3027 7.2045C13.494 7.2045 13.6808 7.26307 13.838 7.37234C13.9951 7.48161 14.115 7.63635 14.1816 7.81576L16.4316 13.9212C16.4791 14.0501 16.5539 14.1671 16.6511 14.2642C16.7482 14.3614 16.8652 14.4362 16.9941 14.4837L23.0995 16.7337C23.279 16.8003 23.4337 16.9202 23.543 17.0774C23.6522 17.2345 23.7108 17.4213 23.7108 17.6126C23.7108 17.804 23.6522 17.9908 23.543 18.1479C23.4337 18.305 23.279 18.4249 23.0995 18.4915L16.9941 20.7415C16.8652 20.789 16.7482 20.8639 16.6511 20.961C16.5539 21.0581 16.4791 21.1752 16.4316 21.304V21.304Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.8027 2.61264V8.23764'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.6152 5.42514H17.9902'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.4277 9.17514V12.9251'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.3027 11.0501H24.5527'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
