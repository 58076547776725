import Circle from '@howdy/core/elements/Circle'
import Card from '.'

export default function IconCard({ children, icon, color }) {
  return (
    <div className='relative mx-4 mt-4 flex w-full flex-col items-center'>
      <div className='absolute top-[-4px]'>
        <Circle bgColor='white' borderColor='#DCDCDC' className='text-howdy-primary-green'>
          {icon}
        </Circle>
      </div>
      <div className='m-6 w-full rounded-lg border border-[#F2F2F2] p-6 shadow-[4px_4px_8px_0px_rgba(0,0,0,0.10)]'>
        <div className='w-full pb-[24px] pt-[32px]'>{children}</div>
      </div>
    </div>
  )
}
