export default function CarouselItem({ children, width }) {
  return (
    <div
      className={`inline-flex items-center justify-center whitespace-pre-wrap`}
      style={{ width }}
    >
      {children}
    </div>
  )
}
